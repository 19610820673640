import { useRouter } from 'next/router'
import { Key, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Editable } from 'components/blocks/deprecated/Editable'
import { OldButton } from 'components/ui/deprecated/OldButton'
import { Text } from 'components/ui/deprecated/Text'
import useDrawer from 'lib/hooks/useDrawer'
import { useTranslation } from 'lib/i18n'
import {
  DynamicFormStepsStoryblok,
  FormStepStoryblok,
} from 'lib/storyblok/types'
import {
  FormField,
  getEmailText,
  getFieldComponent,
  parseWithFormValues,
  sendEmail,
} from 'lib/utils/forms'
import { GTMEvents, trackEvent } from 'lib/utils/gtm'

import * as S from './DynamicForm.style'
import { DynamicFormHeader } from './DynamicFormHeader'

type DynamicFormStepsProps = {
  block: DynamicFormStepsStoryblok
}

export const DynamicFormSteps = ({
  block,
  ...props
}: DynamicFormStepsProps): JSX.Element => {
  const { email_address, submit_label, success_message, steps } = block
  const { i18n } = useTranslation()
  const router = useRouter()
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    trigger,
  } = useForm()

  const { drawer } = useDrawer()
  const isDrawer = !!drawer

  const [activeStep, setActiveStep] = useState(0)
  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === steps.length - 1

  const [loading, setLoading] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const [messageError, setMessageError] = useState(false)

  const asPath = router.asPath.substring(1)
  const url = `${process.env.NEXT_PUBLIC_ORIGIN}${asPath}`

  const fields = useMemo(() => {
    return steps.reduce<FormStepStoryblok['fields']>((acc, step) => {
      return [...acc, ...step.fields]
    }, [])
  }, [steps])

  const onPrevious = () => {
    setActiveStep(activeStep - 1)
  }

  const onNext = async () => {
    const names = steps[activeStep].fields.map((field) => field.name || '')
    const validate = await trigger(names)

    if (validate) {
      setActiveStep(activeStep + 1)
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)
    setMessageError(false)

    const to = parseWithFormValues(email_address, data).replace(
      /\[(.*?)\]/g,
      ''
    )

    try {
      const recipients = to.split(';')
      const message = await getEmailText(data, fields, {
        recipients: recipients.join(', '),
        url,
      })

      if (!recipients || !message || !url) {
        throw new Error(
          'Missing one or more of the required fields: recipients, message, url'
        )
      }

      const customerName = getValues('first-name') || getValues('name') || ''
      const customerEmail = getValues('email') || getValues('Email')

      const response = await sendEmail({
        to: recipients,
        message,
        formURL: url,
        customer: {
          name: customerName,
          email: customerEmail,
        },
      })

      if (!response.ok) {
        throw new Error('Error sending email')
      }

      trackEvent(GTMEvents.FormSubmission, {
        'tfp-email-address': to,
      })
      setMessageSent(true)
    } catch {
      setMessageError(true)
    } finally {
      setLoading(false)
    }
  })

  const resetForm = () => {
    setLoading(false)
    setMessageError(false)
    setMessageSent(false)
  }

  return (
    <S.Section
      as="section"
      variant={isDrawer ? 'full' : 'extra-narrow'}
      {...props}
    >
      <S.Wrapper isDrawer={isDrawer}>
        {block.title && <DynamicFormHeader content={block} />}

        {!messageSent ? (
          <S.Form onSubmit={onSubmit}>
            {steps[activeStep]?.title && (
              <Text as="h3" variant="eighteen">
                {activeStep + 1} — {steps[activeStep].title}
              </Text>
            )}
            {steps[activeStep]?.fields?.map(
              (field: FormField, idx: Key | null | undefined) => (
                <Editable block={field} key={idx}>
                  {getFieldComponent({
                    field,
                    getValues,
                    errors,
                    register,
                    control,
                  })}
                </Editable>
              )
            )}

            <S.ButtonsWrapper>
              <OldButton
                disabled={loading || isFirstStep}
                variant="ghost"
                className="mt-5"
                onClick={onPrevious}
              >
                {i18n('previous')}
              </OldButton>

              {isLastStep ? (
                <OldButton
                  disabled={loading || !isLastStep}
                  isLoading={loading}
                  variant="solid"
                  type="submit"
                  className="mt-5"
                >
                  {submit_label}
                </OldButton>
              ) : (
                <OldButton
                  disabled={loading || isLastStep}
                  variant="solid"
                  onClick={onNext}
                  className="mt-5"
                >
                  {i18n('next')}
                </OldButton>
              )}
            </S.ButtonsWrapper>

            {messageError && (
              <S.ErrorText as="span" variant="fourteen" className="mt-4">
                {i18n('contact.error.message')}
              </S.ErrorText>
            )}
          </S.Form>
        ) : (
          <>
            <S.SentBox>
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.1953 0.25C8.26695 0.25 6.38189 0.821828 4.77851 1.89317C3.17513 2.96451 1.92545 4.48726 1.18749 6.26884C0.449537 8.05042 0.256454 10.0108 0.63266 11.9021C1.00887 13.7934 1.93746 15.5307 3.30103 16.8943C4.66459 18.2579 6.40187 19.1865 8.29319 19.5627C10.1845 19.9389 12.1449 19.7458 13.9265 19.0078C15.7081 18.2699 17.2308 17.0202 18.3021 15.4168C19.3735 13.8134 19.9453 11.9284 19.9453 10C19.9426 7.41498 18.9145 4.93661 17.0866 3.10872C15.2587 1.28084 12.7803 0.25273 10.1953 0.25ZM14.4759 8.28063L9.22594 13.5306C9.15629 13.6004 9.07357 13.6557 8.98252 13.6934C8.89147 13.7312 8.79388 13.7506 8.69532 13.7506C8.59676 13.7506 8.49916 13.7312 8.40811 13.6934C8.31706 13.6557 8.23435 13.6004 8.16469 13.5306L5.91469 11.2806C5.77396 11.1399 5.6949 10.949 5.6949 10.75C5.6949 10.551 5.77396 10.3601 5.91469 10.2194C6.05542 10.0786 6.24629 9.99958 6.44532 9.99958C6.64434 9.99958 6.83521 10.0786 6.97594 10.2194L8.69532 11.9397L13.4147 7.21937C13.4844 7.14969 13.5671 7.09442 13.6581 7.0567C13.7492 7.01899 13.8468 6.99958 13.9453 6.99958C14.0439 6.99958 14.1414 7.01899 14.2325 7.0567C14.3235 7.09442 14.4063 7.14969 14.4759 7.21937C14.5456 7.28906 14.6009 7.37178 14.6386 7.46283C14.6763 7.55387 14.6957 7.65145 14.6957 7.75C14.6957 7.84855 14.6763 7.94613 14.6386 8.03717C14.6009 8.12822 14.5456 8.21094 14.4759 8.28063Z"
                    fill="#F8F5F1"
                    fill-opacity="0.8"
                  />
                </svg>
              </div>

              <Text as="span" variant="sixteen" className="ml-3">
                {parseWithFormValues(success_message, getValues()) ||
                  i18n('contact.messageSent')}
              </Text>
            </S.SentBox>

            <div>
              <OldButton variant="ghost" onClick={resetForm}>
                {i18n('contact.writeAnotherMessage')}
              </OldButton>
            </div>
          </>
        )}
      </S.Wrapper>
    </S.Section>
  )
}
